<!-- 대행사 등록/수정 -->
<template>
  <v-container
    id="register"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
      <base-material-card
              color="green">
        <template v-slot:heading>
          <h1 class="display-2 font-weight-regular">
            <span v-if="vModel.data.company_name">
              {{ vModel.data.company_name }} >
            </span>
            대행사 {{agencyType}}
          </h1>
          <div class="subtitle-1 font-weight-regular">
          대행사를 {{agencyType}}하세요.
        </div>
      </template>
        <v-card-text>
          <h2 style="padding: 20px 10px;">
            대행사 정보 {{agencyType}}
          </h2>
          <v-form
                  v-model="vModel.valid"
                  ref="form">
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                회사명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="회사명"
                        type="text"
                        name="company_name"
                        color="secondary"
                        v-model="vModel.data.company_name"
                        :rules="config.mediaKorNameRules"
                        required />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                사업자 번호
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="사업자 번호"
                        type="text"
                        name="company_num"
                        color="secondary"
                        v-model="vModel.data.company_num"
                        :rules="config.companyNumRules"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                담당자명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="담당자명"
                        type="text"
                        name="name"
                        color="secondary"
                        v-model="vModel.data.user.name" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                계정 이메일
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="계정 이메일"
                        type="text"
                        name="email"
                        color="secondary"
                        v-model="vModel.data.user.email"
                        :rules="config.emailRules"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                전화
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="전화"
                        type="tel"
                        name="tel"
                        color="secondary"
                        v-model="vModel.data.tel"
                        :rules="config.telRules"
                        required />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                모바일
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="모바일"
                        type="tel"
                        name="mobile"
                        color="secondary"
                        ref="email"
                        v-model="vModel.data.mobile"
                        :rules="config.mobileRules"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                비밀번호
              </v-col>
              <v-col cols="5"
                     v-if="$route.name === 'agencyRegister'">
                <v-text-field
                        placeholder="비밀번호"
                        type="password"
                        name="password"
                        color="secondary"
                        v-model="vModel.data.user.password"
                        :rules="config.passwordRules"
                        required />
              </v-col>
              <v-col cols="5" v-else>
                <v-text-field
                        placeholder="비밀번호"
                        type="password"
                        name="password"
                        color="secondary"
                        v-model="vModel.data.user.password" />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                비밀번호 확인
              </v-col>
              <v-col cols="5" v-if="$route.name === 'agencyRegister'">
                <v-text-field
                        placeholder="비밀번호 확인"
                        type="password"
                        name="password2"
                        color="secondary"
                        v-model="vModel.data.user.passwordConfirmation"
                        :rules="config.passwordConfirmationRules"
                        required />
              </v-col>
              <v-col cols="5" v-else>
                <v-text-field
                        placeholder="비밀번호 확인"
                        type="password"
                        name="password2"
                        color="secondary"
                        v-model="vModel.data.user.passwordConfirmation" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                   align="center">
              <v-col
                cols="1"
                class="text-right grey--text">
                제휴 상태
              </v-col>
              <v-col cols="5">
                <v-select :items="config.contractStatusOptions"
                          v-model="vModel.data.status"
                          placeholder="제휴 상태"
                          color="secondary" />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                   v-if="response.mediaList">
                <v-col cols="1"
                        class="text-right grey--text">
                  매체 지정
                </v-col>
                <v-col cols="5">
                  <div style="max-height:350px;overflow-y: auto;border: solid 1px #ddd;border-width: 1px 0;">
                    <v-data-table
                            style="width:100%"
                            :headers="config.headers"
                            :items="response.mediaList"
                            :items-per-page="Number(response.mediaList.length)"
                            hide-default-footer>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-center">{{item.id}}</td>
                          <td class="text-center">{{item.kor_name}}</td>
                          <!--대행중인 매체 있을때-->
                          <template v-if="response.agency.medias && response.agency.medias.length > 0">
                            <td class="text-center">
                              <!--매체 미대행-->
                              <template v-if="resultLinkMedias(item)">
                                -
                              </template>
                              <!--매체 대행중-->
                              <template v-else>
                                대행중
                              </template>
                            </td>
                            <td class="text-center">
                              <!--매체 미대행-->
                              <template v-if="resultLinkMedias(item)">
                                <v-btn @click="postLinkMedias(item)" small rounded color="success">대행지정</v-btn>
                              </template>
                              <!--매체 대행중-->
                              <template v-else>
                                <v-btn @click="deleteLinkMedias(item)" small rounded color="waring">대행취소</v-btn>
                              </template>
                            </td>
                          </template>
                          <!--구독중인 cp 없을때-->
                          <template v-else>
                            <td class="text-center">
                              -
                            </td>
                            <td class="text-center">
                              <v-btn @click="postLinkMedias(item)" small rounded color="success">대행지정</v-btn>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            <v-row justify="center" class="mt-5">
              <template v-if="$route.name === 'agencyRegister'">
                <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="success"
                    @click="submitRegister">
                    등록
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                        class="mt-5"
                        color="default"
                        @click="beforePage">
                  취소
                </v-btn>
                <v-btn
                        :disabled="!vModel.valid"
                        class="mt-5"
                        color="success"
                        @click="submitEdit">
                  수정
                </v-btn>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
      </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        register: null,
        edit: null,
        postLink: null,
        deleteLink: null,
      },
      vModel: {
        valid: true,
        data: {
          name: '',
          company_name: '',
          company_num: '',
          tel: '',
          mobile: '',
          medias: '',
          user: {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
          },
          contract_status_id: 1
        },
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      config: {
        headers: [
          { text: 'ID', value: 'id', align: 'center' },
          { text: 'CP명', value: 'name', align: 'center' },
          { text: '상태', value: 'status', align: 'center' },
          { text: '버튼', value: 'btn', align: 'center' },
        ],
        nameRules: [ v => !!v || '이름을 입력하세요.' ],
        emailRules: [
          v => !!v || '계정 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.'
        ],
        passwordRules: [ v => !!v || '비밀번호를 입력하세요.',
          v => v.length > 5 || '비밀번호를 6자 이상 입력하세요.' ],
        passwordConfirmationRules: [
          v => !!v || '비밀번호를 재입력하세요.',
          v => this.vModel.data.user.password === v || '입력한 비밀번호와 같은 값을 입력하세요.' ],
        mediaKorNameRules: [ v => !!v || '매체명을 입력하세요.' ],
        mediaNameRules: [ v => !!v || '호스트명을 입력하세요.' ],
        managerNameRules: [ v => !!v || '담당자명를 입력하세요.' ],
        site3NoRules: [ v => !!v || '미디어 번호를 입력하세요.' ],
        managerPhoneRules: [ v => !!v || '전화번호를 입력하세요.',
          v => v.length > 9 || '전화번호를 10자 이상 입력하세요.' ],
        managerEmailRules: [ v => !!v || '담당자 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        ceoRules: [ v => !!v || '대표자명을 입력하세요.' ],
        addressRules: [ v => !!v || '주소를 입력하세요.' ],
        businessNoRules: [ v => !!v || '사업자 등록 번호를 입력하세요.' ],
        taxEmailRules: [ v => !!v || '세금계산서를 발행 받을 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        bankNameRules: [ v => !!v || '은행명 입력하세요.' ],
        bankAccountRules: [ v => !!v || '계좌번호를 숫자만 입력하세요.',
          v => v.length > 9 || '계좌번호를 10자 이상 입력하세요.' ],
        contractStatusOptions: [
          // { value: 'all', text: '전체' },
          { value: 1, text: '등록' },
          { value: 2, text: '준비 중' },
          { value: 3, text: '제휴 중' },
          { value: 4, text: '일시 정지' },
          { value: 6, text: '보류' },
          { value: 5, text: '중단' }
        ],
        linkMedias: []
      },
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      let name = this.$route.name
      if (name === 'agencyEdit') {
        this.getMedias()
      }
    },
    /* 매체 선택하기 */
    postLinkMedias (val) {
      if (confirm('매체대행을 지정하겠습니까?')) {
        this.xhttp({
          url: '/' + this.$route.params.agency_id + '/link2/' + val.key,
          method: 'post',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.postLink = response.data.data
            this.snack('success', '매체대행이 지정되었습니다.')
            this.getAgency()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 매체 선택 취소하기 */
    deleteLinkMedias (val) {
      if (confirm('매체대행을 취소하겠습니까?')) {
        this.xhttp({
          url: this.$route.params.agency_id + '/link2/' + val.key,
          method: 'delete',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.deleteLink = response.data.data
            this.snack('success', '매체대행이 취소되었습니다.')
            this.getAgency()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '매체대행 취소 실패! 관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 매체 선택 확인 */
    resultLinkMedias (val) {
      let list = this.config.linkMedias.filter(function ($value) {
        return $value.id === val.id
      })
      if (list.length > 0) {
        // 선택한거
        return false
      } else {
        // 선택안한거
        return true
      }
    },
    /* 매체 선택 신청 중일때 해당값 return */
    resultLinkMedias2 (val) {
      let list = this.config.linkMedias.filter(function ($value) {
        return $value.id === val.id
      })
      return list[0].value
    },
    /* 대행사 상세정보 가져오기 */
    getAgency () {
      let id = this.$route.params.agency_id
      this.xhttp({
        url: '/agency/' + id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.agency = response.data.data.agency
          this.vModel.data = this.response.agency
          this.vModel.data.mode = 'default'
          if (!this.vModel.data.user) {
            this.vModel.data.user = { name: '', email: '' }
          }
          this.vModel.data.user.password = ''
          this.vModel.data.user.passwordConfirmation = ''
          this.config.linkMedias = null
          if (this.response.agency.medias.length > 0) {
            this.config.linkMedias = this.response.agency.medias.map(function (val) {
              return { id: val.id, value: val.kor_name }
            })
          }
          let list = this.response.media
          let arr = []
          if (this.config.linkMedias) {
            /* 선택된 매체 먼저 arr에 넣기 */
            for (let i = 0; i < list.length; i++) {
              if (!this.resultLinkMedias(list[i])) {
                arr.push(list[i])
              }
            }
            /* 선택되지 않은 매체명을 arr 에 넣기 */
            for (let i = 0; i < list.length; i++) {
              if (this.resultLinkMedias(list[i])) {
                arr.push(list[i])
              }
            }
          } else {
            arr = list
          }
          this.response.mediaList = arr
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '대행사 정보 불러오기 실패! 관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 매체 리스트 가져오기 */
    getMedias () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'name',
        direction: 'asc',
        contract_status_id: 'for_subscription'
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.mediasOptions = this.response.media.map(function ($value) {
            return { value: $value.id, text: $value.kor_name }
          })
          this.getAgency()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '매체목록 불러오기 실패! 관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 대행사 등록하기 */
    submitRegister () {
      if (this.$refs.form.validate()) {
        if (confirm('등록 하시겠습니까?')) {
          let send = this.vModel.data
          this.xhttp({
            url: '/agency',
            method: 'post',
            data: send
          }).then((response) => {
            if (response.status === 201) {
              this.response.register = response.data.data
              this.snack('success', '대행사 등록이 완료되었습니다.')
              this.$router.push({ name: 'agencyList' })
            }
          }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
              this.$router.push({ 'name': 'UserLogin' })
            } else {
              this.snack('warning', '관리자에게 문의 바랍니다.')
            }
          })
        }
      }
    },
    /* 매체 수정버튼 클릭 */
    submitEdit () {
      let id = this.$route.params.agency_id
      if (this.$refs.form.validate()) {
        let password = this.vModel.data.user.password
        if (password) {
          if (password.length > 6) {
            let passwordConfirm = this.vModel.data.user.passwordConfirmation
            if (passwordConfirm) {
              if (password === passwordConfirm) {
                this.putUser(id)
              } else {
                this.snack('warning', '입력한 비밀번호와 같은 값을 입력하세요.')
              }
            } else {
              this.snack('warning', '비밀번호 확인을 입력하세요.')
            }
          } else {
            this.snack('warning', '비밀번호 6자 이상 입력하세요.')
          }
        } else {
          this.putUser(id)
        }
      }
    },
    /* 매체 수정하기 */
    putUser (id) {
      if (confirm('수정 하시겠습니까?')) {
        let send = this.vModel.data
        this.xhttp({
          url: '/agency/' + id,
          method: 'put',
          data: send
        }).then((response) => {
          if (response.status === 200) {
            this.response.edit = response.data.data
            this.snack('success', '대행사 정보 수정 완료되었습니다.')
            this.beforePage()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 이메일 input 벗어났을때 */
    emailCheck () {
      if (this.$route.name === 'agencyRegister') {
        let v = this.vModel.data.user.email
        // 이메일 형식일때
        if (/.+@.+\..+/.test(v)) {
          this.postEmailValidate()
        }
      }
    },
    /* 이메일 중복 체크 */
    postEmailValidate () {
      let send = {
        class: 'Media\\MediaStoreRequest',
        user: {
          email: this.vModel.data.user.email
        }
      }
      this.xhttp({
        url: '/validation',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.validation = response.data
          if (!this.response.validation.success) {
            if (this.response.validation.errors['user.email']) {
              this.snack('warning', '이미 사용중인 이메일 입니다.')
              this.$refs.email.focus()
            }
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
  },
  computed: {
    managerPhoneMask () {
      let numberLength = this.vModel.data.company.managerPhone
      if (numberLength === 9) {
        return '####-####'
      } else if (numberLength === 11) {
        return '##-###-####'
      } else if (numberLength === 12) {
        return '###-###-####'
      } else {
        return '###-####-####'
      }
    },
    businessNoMask () {
      return '###-##-######'
    },
    agencyType () {
      if (this.$route.name === 'agencyEdit') {
        return '수정'
      } else {
        return '등록'
      }
    },
  },
  watch: {
    /* 카테고리가 체크될때 */
    acceptCategoriesData: function (val) {
      if (val) {
        this.vModel.data.acceptCategories = val.map(function ($value) {
          return $value.id
        })
      }
    }
  }
}
</script>

<style>
  #register .v-data-table__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12)
  }

  #register .v-data-table-header {
  background: #ddd
  }

  #register .category-list {
    cursor: pointer;
  }
  #register .category-list:hover {
    font-weight: bold;
  }
</style>
